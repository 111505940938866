section.hero-section {
    min-height:400px;
    height: 1010px;

    .h2 {
        font-size: 70px;
        font-weight: 300;
        line-height: 1.1em;
        letter-spacing: 0;

        span {
            font-weight: 600 !important;
        }
    }
    p {
        font-size: 22px;
        line-height: 1.4em;
    }
    .btn {
        font-weight: 600;
        padding: 10px 40px;
    }
    .btn-white {
        color: #057fb7;
    }
    .btn-white:hover {
        color: #fff !important;
        border-color: #057fb7;
        background-color: #057fb7;
        box-shadow: 0px 0px 25px 0px #113366;
    }
    .mw {
        max-width: 1228px;
        margin: 0 auto;
    }
    .mod {
        min-height:400px;
        height: 1010px;

        .display-1 {
            letter-spacing: 0.15em;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 620px;
        }
        .mod {
            height: 620px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            height: 51vh;
        }
        .mod {
            height: 51vh;
        }
    }
}