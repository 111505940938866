
section.services-section {
    background-color: rgba($color: #E5EAEF, $alpha: 0.50);

    .text-fx .mod {
        padding: 70px 0;
        min-height: 206px;
        background-color: #FFFFFF;
        -webkit-transition: background-color 0.35s;
        transition: background-color 0.35s;
    }
    .text-fx h5 {
        font-family: proxima-nova, sans-serif;
        font-size: 22px;
        letter-spacing: 0;
        text-transform: capitalize;
        color: #415276;
        font-weight: 400;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,65%,0);
        transform: translate3d(0,65%,0);
    }
    .text-fx .mod::before,
    .text-fx p {
        font-family: proxima-nova, sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: rgba($color: #FFFFFF, $alpha: 0.66);
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .text-fx:hover h5 {
        color: #FFFFFF;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    .text-fx:hover .mod::before,
    .text-fx:hover p {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .35s;
        -moz-transition: all .35s;
        transition: all .35s;
    }
    .text-fx:hover .mod {
        background-color: $primary;
    }
    
}